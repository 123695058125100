<template>
  <div><i class="icon-menu mr-1"></i>Registration List<hr />
    <div v-if="event.title" class="container-fluid">
      <h4><router-link 
          v-b-popover.hover="'View the event'" 
          :to="{ name: 'eventDetails', params: { eid: event.id } }"
        >{{ event.title }}</router-link> Registrations</h4> 
    </div>

    <!-- FILTERS -->
    <b-row class="mb-3" v-if="!eid">
      <b-col cols="12" lg="3">
        <b-form-group
          description="Filter by event or leave as '-- Active Events --'."
          label="Event"
          label-for="table-registrations-event"
          label-sr-only
        >
          <b-form-select
            id="table-registrations-event"
            v-model="queryFilter['event']"
            :options="events"
            value-field="id"
            text-field="title" 
            @input="(v) => {v ? queryFilter['event.status'] = null: null}"
          >
            <template v-slot:first>
              <option :value="null">-- Active Events --</option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" lg="1" class="d-none d-md-block text-center">
        <p>-or-</p>
      </b-col>

      <b-col cols="12" lg="3">
        <b-form-group
          description="Filter by status or leave as '-- Event Status --'."
          label="Event Status"
          label-for="table-registrations-event-status"
          label-sr-only
        >
          <b-form-select id="table-registrations-event-status" v-model="queryFilter['event.status']" :options="[
            { text: 'Open', value: 2 },
            { text: 'Pending', value: 1 },
            { text: 'Archived', value: 5 }
          ]" @input="(v) => {v ? queryFilter['event'] = null: null}">
            <template v-slot:first>
              <option :value="null">Event Status</option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- b-col cols="12" lg="1" class="d-none d-md-block text-center">
        <p> | </p>
      </b-col>

      <b-col cols="12" lg="3">
        <b-form-group
          description="Filter by payment status or leave as '-- Payment Status --'."
          label="Payment Status"
          label-for="table-registrations-payment-status"
          label-sr-only
        >
          <b-form-select id="table-registrations-payment-status" v-model="queryFilter['event.paymentStatus']" :options="[
            { text: 'Paid', value: 'paid' },
            { text: 'Pending', value: 'pending' },
            { text: 'Unpaid', value: 'unpaid' },
            { text: 'Notified', value: 'notified' },
            { text: 'Terminate', value: 'terminate' },
            { text: 'No Invoice', value: 'no-invoice' }
          ]" @input="(v) => {v ? queryFilter['event'] = null: null}">
            <template v-slot:first>
              <option :value="null">Payment Status</option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col -->

    </b-row>
    
    <b-row>
      <b-col>
        <b-dropdown id="dropdown-1" text="With Selected..." class="m-md-2">
          <b-dropdown-item @click="deleteMultipleRegistrations">Delete</b-dropdown-item>
          <b-dropdown-item @click="terminateMultipleRegistrations">Terminate</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col class="text-right">
        <b-button :to="{ name: 'Compose', params: { eid: event.id ?? events.map(e => e.id), message: 'unpaid' } }"><i class="fa fa-envelope"></i> Message Unpaid</b-button>
      </b-col>
    </b-row>

    <!-- DATA -->
    <v-server-table 
      ref="table-registrations" 
      id="table-registrations" 
      class="bg-light p-2 mb-4" 
      name="dataTableEventRegistrationList" 
      :url="url"
      :columns="columns"
      :options="options" 
      :useVuex="true"
      :theme="theme" 
      :preserveState="preserveState"
      @select="selected = $event"
    >
      <template slot="child_row" slot-scope="props">
        <span v-if="props.row.request">{{props.row.request}}</span>
        <span v-else class="text-center text-muted">No additional information</span>
      </template>
      <template slot="datetime" slot-scope="props">
        {{ $moment(props.row.datetime).format('MMMM Do \'YY, h:mm a') }}
      </template>
      <template slot="participant.fullName" slot-scope="props">
        <router-link v-b-popover.hover="'View the participant'" :to="{ name: 'participantDetails', params: { pid: props.row.participant.id } }">{{ props.row.participant.fullName }}</router-link>
      </template>
      <template slot="participant.dob" slot-scope="props">
        {{ $moment((($moment().diff(props.row.event.startDate) > 0) ? $moment() : props.row.event.startDate)).diff(props.row.participant.dob, 'years') }}
      </template>
      <template slot="user.fullName" slot-scope="props"><a href="javascript:;" @click="$refs.userViewModal.open(props.row.user.id)">{{ props.row.user.fullName }}</a></template>
      
      <template slot="invoiceItem.price" slot-scope="props">
        <router-link v-b-popover.hover="'View the invoice'" v-if="props.row.invoiceItem?.price" :to="{name: 'invoiceDetails', params: { iid: props.row.invoice.id }}">${{ props.row.invoiceItem.price }}</router-link>
      </template>
      
      <template slot="status" slot-scope="props">
        <div class="text-center">
          <span v-if="props.row.status === 'paid'" class="bg-success px-3 py-1">Paid</span>
          <span v-if="props.row.status === 'pending'" class="bg-warning px-3 py-1">Pending</span>
          <span v-if="props.row.status === 'unpaid'" class="bg-danger px-3 py-1">Unpaid</span>
          <span v-if="props.row.status === 'notified'" class="bg-primary px-3 py-1">Notified</span>
          <span v-if="props.row.status === 'terminate'" class="bg-dark px-3 py-1">Terminate</span>
          <span v-if="props.row.status === 'no-invoice'" class="border border-danger px-3 py-1">No&nbsp;Invoice</span>
        </div>
      </template>

      <div slot="actions" slot-scope="props" class="text-center">
        <b-button-group>
          <b-btn title="View the registration" size="sm" variant="info" :to="{ name: 'eventRegistrationDetails', params: { rid: props.row.id } }">View</b-btn>
          <b-btn title="Edit the registration" size="sm" variant="warning" :to="{ name: 'eventRegistrationForm', params: { rid: props.row.id }}">Edit</b-btn>
          <b-btn title="Delete the registration" size="sm" variant="danger" @click.prevent="deleteSingleRegistration(props.row)">
            <b-spinner v-if="deleteWorking.includes(props.row.id)" class="btn-delete-active" small variant="light" type="grow"></b-spinner>Delete
          </b-btn>
        </b-button-group>
      </div>
    </v-server-table>
    <b-row>
      <b-col>
        <b-dropdown id="dropdown-1" text="With Selected..." class="m-md-2">
          <b-dropdown-item @click="deleteMultipleRegistrations">Delete</b-dropdown-item>
          <b-dropdown-item @click="terminateMultipleRegistrations">Terminate</b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col class="text-right">
        <b-button :to="{ name: 'Compose', params: { eid: event.id ?? events.map(e => e.id), message: 'unpaid' } }"><i class="fa fa-envelope"></i> Message Unpaid</b-button>
      </b-col>
    </b-row>
    <!-- view member -->
    <UserViewModal ref="userViewModal" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { HTTP } from '../../services/http-common'
import { ServerTable /* , ClientTable Event */ } from 'vue-tables-2-premium'
import UserViewModal from '../../components/UserViewModal'
// import EventParticipantsExport from '@/components/EventParticipantsExport'

Vue.use(ServerTable)
// Vue.use(ServerTable, {}, false, 'bootstrap4', 'default')

export default {
  name: 'EventRegistrationList',
  components: {
    UserViewModal,
    // EventParticipantsExport
  },
  data () {
    return {
      event: {
        id: null,
        title: null
      },
      options: {
        debounce: 750,
        perPage: 30,
        perPageValues: [30,60,90],
        selectable:{
          mode: 'multiple',
          // only: (row) => {
          //   return row.invoice.transactionId === null
          // },
          selectAllMode: 'page',
          programmatic: false
        },
        async requestFunction (data) {
          return await HTTP.get(this.url, {
              params: data
          }).catch(function (e) {
              this.dispatch('error', e)
          }.bind(this))
        },
        requestAdapter (data) {
          let query = {
            page: data.page ? data.page : '1',
            itemsPerPage: data.limit ? data.limit : DEFAULT_PER_PAGE
          }
          if (data.query !== '') {
            query['search_name'] = data.query
          }
          if (data.orderBy) {
            let orderBy = data.orderBy === 'status' ? 'invoice.transactionId' : data.orderBy
            let param = 'order[' + orderBy + ']'
            query[param] = data.ascending ? 'asc' : 'desc'
          } else {
            query['order[' + DEFAULT_QS_DATE_TYPE + ']'] = 'desc'
          }
          return query
        },
        responseAdapter ({data}) {
          const list = []
          for (let i in data['hydra:member']) {
            let eventRegistration = { ...data['hydra:member'][i] }
            for (let k in eventRegistration) {
              switch (k) {
                case 'invoice': 
                  // INVOICE STATUS
                  if (eventRegistration.invoice?.transactionId) {
                    eventRegistration['status'] = 'paid'
                  }
                  else if (eventRegistration.invoice?.paymentMethod && !eventRegistration.invoice.transactionId) {
                    eventRegistration['status'] = 'pending'
                  }
                  else if (eventRegistration.invoice?.timesNotified) {
                    if (this.$moment().diff(this.$moment(eventRegistration.datetime), 'days') > 5) {
                      eventRegistration['status'] = 'terminate'
                    } else {
                      eventRegistration['status'] = 'notified'
                    }
                  }
                  else if (!eventRegistration.invoice?.transactionId) {
                    eventRegistration['status'] = 'unpaid'
                  }
                  else {
                    eventRegistration['status'] = 'no-invoice'
                  }
                  // REGISTRATION PRICE
                  if (eventRegistration.invoice && eventRegistration.invoiceItem) {
                    eventRegistration['price'] = eventRegistration.invoiceItem.price.toString()
                  }
                  else {
                    eventRegistration['price'] = null
                  }
                  break
              }
            }
            list.push(eventRegistration)
          }
          return {
            data: list,
            count: data['hydra:totalItems']
          }
        },
        headings: {
          id: 'RID',
          'participant.dob': 'Age',
          'user.fullName': 'Member',
          'participant.fullName': 'Participant',
          'event.title': 'Event',
          coachVolunteer: 'Coach',
          'invoiceItem.price': 'Price',
          actions: 'Actions'
        },
        sortable: [
          'id',
          'participant.fullName',
          'event.title',
          'user.fullName',
          'participant.dob',
          'invoiceItem.price',
          'status',
          'coachVolunteer',
          'datetime'
        ],
        orderBy: {
          column: 'id',
          ascending: false
        },
        filterable: ['id', 'participant.fullName', 'event.title', 'user.fullName', 'invoiceItem.price', 'status', 'coachVolunteer', 'datetime'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      selected: [],
      preserveState: true,
      useVuex: true,
      theme: 'bootstrap4',
      template: 'default',
      queryFilter: {
        id: null,
        'participant.fullName': null,
        'event.title': null,
        'user.fullName': null,
        event: null,
        coachVolunteer: null,
        'datetime[after]': null,
        'datetime[before]': null,
        'event.transactionId': null,
        'event.status': null,
        'event.paymentStatus': null
      },
      deleteWorking: []
    }
  },
  computed: {
    ...mapGetters([
      'eventRegistrations',
      'events'
    ]),
    eid () {
      return this.$route.params.eid
    },
    url () {
      const queryFilter = { ...this.queryFilter }
      const params = {}
      for (const k in queryFilter) {
        if (queryFilter[k]) params[k] = queryFilter[k]
      }
      if (this.currentBusiness.id) {
        params.business = this.currentBusiness.id
      }
      if (this.event.id) {
        params.event = this.event.id
      }
      if (this.$route.params.eid) {
        params.event = this.$route.params.eid
      }
      const query = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      return '/event_registrations?' + query
    },
    columns () {
      let columns = [
        'id',
        'participant.fullName',
        'participant.dob',
        'event.title',
        'datetime',
        'user.fullName',
        'invoiceItem.price',
        'status',
        'coachVolunteer',
        'actions'
      ]
      if (this.eid) {
        columns.splice(3,1)
      }
      return columns
    },
  },
  created () {
    // this.doGetEventRegistrations()
  },
  async mounted () {
    if (this.eid) {
      this.queryFilter.event = this.eid
      await this.setEvent(this.eid)
    }
    // load events for filter
    await this.getEvents({
      business: this.currentBusiness.id,
      status: [1,2]
    })
    if (this.$route.params.uid) {
      this.$refs.userViewModal.open(this.$route.params.uid)
    }
  },
  destroyed () {
    this.event = {}
    this.resetEventRegistrations()
  },
  watch: {
    'queryFilter.event': async function (val) {
      if (val) {
        this.setEvent(val)
      } else {
        this.event = {}
      }
    },
    eid: async function (val) {
      if (val) {
        this.queryFilter.event = val
      } else {
        this.queryFilter.event = null
      }
    }
  },
  methods: {
    ...mapActions([
      'getEvent',
      'getEvents',
      'getEventRegistration',
      'deleteEventRegistration',
      'getInvoice',
      'deleteInvoice',
      'terminateInvoice',
      'getEventRegistrations',
      'resetEventRegistrations'
    ]),
    async setEvent (eventId) {
      const event = await this.getEvent({
        id: eventId
      })
      this.event.title = event.title
      this.event.id = eventId
      return event
    },
    getParticipantsData () {
      return HTTP.get(this.url, {
          params: this.options.requestAdapter({})
      }).then(participants => {
        return participants.data["hydra:member"]
      })
    },
    async doGetEventRegistrations () {
      let params = {}
      if (this.eid) {
        this.event.id = this.eid
        params.event = this.eid
        await this.getEvent({
          id: this.event.id
        }).then(resp => {
          this.event.title = resp.title
        })
      }
      // else {
      //   // event ID required
      //   this.$router.push({ name: 'events' })
      // }
      if (this.currentBusiness.id) {
        params.business = this.currentBusiness.id
      }
      params.pagination = false
      await this.getEventRegistrations(params)
    },

    /**
     * Set the deleteWorking array
     * @param {Number} id 
     */
    setDeleteWorking (id) {
      this.deleteWorking.push(id)
    },

    /**
     * Unset the deleteWorking array
     * @param {Number} id 
     */
    unsetDeleteWorking (id) {
      for (var k in this.deleteWorking) {
        if (this.deleteWorking[k] == id) {
          this.deleteWorking.splice(k, 1);
        }
      }
    },

    /**
     * Delete a single registration
     * @param {Object} reg 
     */
    deleteSingleRegistration (reg) {
      this.setDeleteWorking(reg.id)
      // let delInv = false
      // let reg = await this.getEventRegistration(reg.id)
      let msg = 'Please confirm that you want to PERMANENTLY DELETE this registration (RID ' + reg.id + ')'
      // if (reg.invoice) {
      //   let inv = await this.getInvoice({id: reg.invoice.id}) //@FIXME in API so no request is needed?
      //   if (inv.items.length === 1) {
      //     delInv = true
      //     msg += ' AND its associated INVOICE (#' + reg.invoice.id + ')'
      //   } else {
      //     msg += '. The registration will be removed from invoice #' + reg.invoice.id
      //   }
      // }
      msg += '. THIS CANNOT BE UNDONE!'
      this.$bvModal.msgBoxConfirm(msg, {
        title: 'Please Confirm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async confirmed => {
        if (confirmed) {
          await this.deleteRegistration(reg)
          this.$refs['table-registrations'].refresh()
        }
      })
      .catch(err => {
        console.log(err)
        this.deleteWorking = []
        console.log(this.deleteWorking)
      })
    },

    /**
     * Delete multiple registrations
     */
    deleteMultipleRegistrations () {
      for (let i in this.selected) {
        this.setDeleteWorking(this.selected[i].id)
      }
      let msg = 'Please confirm that you want to PERMANENTLY DELETE all selected registrations. THIS CANNOT BE UNDONE!'
      this.$bvModal.msgBoxConfirm(msg, {
        title: 'Please Confirm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async confirmed => {
        if (confirmed) {
          const rp = this.selected.map(reg => {
            return this.deleteRegistration(reg)
          })
          await Promise.all(rp)
          this.$refs['table-registrations'].refresh()
        } else {
          this.deleteWorking = []
        }
      })
      .catch(err => {
        console.log(err)
        this.deleteWorking = []
        console.log(this.deleteWorking)
      })
    },

    terminateMultipleRegistrations () {
      this.deleteWorking = []
      let count = 0;
      for (let i in this.selected) {
        if (
          this.selected[i].invoice.transactionId == null &&
          this.$moment().diff(this.$moment(this.selected[i].datetime), 'days') > 5
        ) {
          this.setDeleteWorking(this.selected[i].id)
          count++
        } else {
          // this.selected.splice(i, 1)
        }
      }

      let msg = 'The Terminate feature will only work on unpaid invoices that have been notified and ' +
        'will send out another notification to the member who submitted the registration. This feature will delete ALL registrations that exist on the invoice. ' +
        'Please confirm that you want to TERMINATE all selected UNPAID & NOTIFIED registrations. THIS CANNOT BE UNDONE!'

      this.$bvModal.msgBoxConfirm(msg, {
        title: 'Please Confirm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async confirmed => {
        if (confirmed) {
          const rp = this.selected.map(reg => {
            return this.terminateRegistration(reg)
          })
          await Promise.all(rp)
          this.$toast.success(count + ' registrations were terminated.')
          this.deleteWorking = []
          this.$refs['table-registrations'].refresh()
        } else {
          this.deleteWorking = []
        }
      })
      .catch(err => {
        console.error(err)
        this.deleteWorking = []
        console.error(this.deleteWorking)
      })
    },

    /**
     * Delete a registration
     * @param {Object} reg 
     */
    async deleteRegistration (reg) {
      try {
        let delInv = false
        if (reg.invoice) {
          let inv = await this.getInvoice({id: reg.invoice.id}) //@FIXME move to API so no request is needed?
          if (inv.items.length === 1) {
            delInv = true
          }
        }
        const deleteEventRegistration = await this.deleteEventRegistration(reg.id)
        if (reg.invoice && delInv) {
          await this.deleteInvoice(reg.invoice.id)
        } else if (reg.invoice) {
          await this.recalculateInvoice(reg.invoice.id)
        }
        // remove from deleteWorking
        this.unsetDeleteWorking (reg.id)
        return deleteEventRegistration
      } catch (err) {
        console.log(err)
      }
    },

    /**
     * Terminate a registration
     * @param {Object} reg 
     */
    async terminateRegistration (reg) {
      try {
        if (reg.invoice.transactionId || reg.invoice.timesNotified == null) {
          // remove from deleteWorking
          this.unsetDeleteWorking (reg.id)
          return;
        }
        const terminateEventRegistration = await this.terminateInvoice(reg.invoice.id)
        // remove from deleteWorking
        this.unsetDeleteWorking (reg.id)
        return terminateEventRegistration
      } catch (err) {
        console.log(err)
      }
    }
  },
}
</script>

<style lang="scss">
.btn-delete-active {
  position: absolute; 
  top: 0; 
  right: 0; 
  bottom: 0; 
  left: 0;  
  width: 100%; 
  height: 100%;
}
#dataTable {
  width: 95%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .vue-title {
    text-align: center ;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
